import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { MultiplePaymentMethodComponent } from '../../../../common/components/multiple-payment-method/multiple-payment-method.component';
import { PaymentMethodButtonSelectorComponent } from '../../../../common/components/payment-method-button-selector/payment-method-button-selector.component';
import { CustomValidators } from '../../../../common/validators/CustomValidators';
import { AuthenticationService } from '../../../../interceptors/authentication.service';
import { LocalData } from '../../../../models/LocalData';
import { CustomerMovementType, MovementType } from '../../../../models/MovementType';
import { CurrencyVM, CustomerBalanceVM, CustomerMovementVM, LoginResponse, PaymentMethodVM, SaleBalanceDto } from '../../../../models/view-models';
import { CurrencyService } from '../../../../services/currency.service';
import { CustomerMovementService } from '../../../../services/customer-movement.service';
import { ShiftService } from '../../../../services/shift.service';
import { LoadingService } from '../../service-pay/common/components/loading/loading.service';
import { AlertifyService } from '../../service-pay/common/services/alertify.service';

@Component({
  selector: 'app-partial-payments',
  templateUrl: './partial-payments.component.html',
  styleUrls: ['./partial-payments.component.scss']
})

export class PartialPaymentsComponent implements OnInit {

  @Input("customerBalance") customerBalance: CustomerBalanceVM;
  selectedSales: SaleBalanceDto[] = [];
  totalSelected: number = 0;

  @ViewChild("amountInput") amountInput!: ElementRef;

  @ViewChild("paymentMethodSelector", { read: PaymentMethodButtonSelectorComponent })
  public paymentMethodSelector: PaymentMethodButtonSelectorComponent;

  hideMultiplePay: boolean = true;
  @ViewChild("multiplePay", { read: MultiplePaymentMethodComponent })
  public multiplePay: MultiplePaymentMethodComponent;

  paymentAmount: number = 0;

  entityForm: FormGroup;
  hideReferenceInput: boolean = true;
  @ViewChild("reference") reference!: ElementRef;

  isCurrencyPaymentMethod: boolean = false;
  currencyCode: string = "";
  currencyTotal: number = 0;
  currencyValue: number = 0;
  totalAmount: number = 0;
  userId: string;
  userRol: string;
  username: string;

  @Output() onCancel = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();

  constructor(private customerMovementService: CustomerMovementService,
    private currencyService: CurrencyService,
    private decimalPipe: DecimalPipe,
    private shiftService: ShiftService,
    private alertify: AlertifyService,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private loading: LoadingService) {

    let usr: LoginResponse = this.authService.SessionUserValue();
    this.userId = usr.UUID;
    this.userRol = usr.Rol;
    this.username = usr.User;

  }

  ngOnInit(): void {
    this.initForm();
    this.focusOnAmountInput();
  }

  initForm() {
    this.entityForm = this.fb.group({
      Amount: [this.setFormatNumber(0), [Validators.required]],
      BankReference: ['', [CustomValidators.removeSpecialCharacters]]
    });
  }

  setFormatNumber(value: any) {
    let tmp = this.decimalPipe.transform(value, '1.2-2', 'mx');
    tmp = tmp.replace(',', '');
    return tmp;
  }

  calculateTotal(sales: SaleBalanceDto[]) {
    this.totalSelected = sales.reduce((acc, sale) =>
      acc + sale.TotalPending, 0);
    this.selectedSales = sales;
  }

  isValid(): boolean {
    return this.paymentAmount > 0
      && this.selectedSales.length > 0
      && this.totalSelected > 0
      && this.paymentAmount <= this.totalSelected;
  }

  save() {
    if (this.isValid()) {
      let newMovement: CustomerMovementVM = Object.assign({
        CustomerId: this.customerBalance.CustomerId,
        MovementType: CustomerMovementType.MULTIPLE_DEPOSIT,
        PaymentMethodCode: this.paymentMethodSelector.getItemCode(),
        PaymentMethodName: this.paymentMethodSelector.getItemName(),
        SalesIds: this.createSalesIdsList(),
        ShiftId: this.shiftService.getActiveShiftId(),
        Username: this.username
      }, this.entityForm.value);

      if (newMovement.PaymentMethodCode === LocalData.PAYMENT_METHOD_MULTIPLE) {
        newMovement.MultiplePayments = this.multiplePay.getCustomerPayments();
      }

      if (this.isCurrencyPaymentMethod) {
        newMovement.Amount = this.totalAmount;
      }

      if (newMovement.Amount > this.customerBalance.Balance
        && !this.customerBalance.PositiveBalanceAllowed) {
        this.alertify.warning("El monto del abono no puede ser mayor al monto pendiente de pago");
      } else {
        this.loading.show();
        this.customerMovementService.createMultipleDeposit(newMovement)
          .subscribe((response: CustomerMovementVM) => {
            if (response) {
              this.onClose.emit();
              this.openTicket(response.TicketUrl)
            }
          }, (err) => {
            this.loading.hide();
          });
      }
    }

  }

  createSalesIdsList(): string[] {
    let result: string[] = [];
    this.selectedSales.forEach(sale => {
      result.push(sale.Sale.UUID);
    });
    return result;
  }

  cancel() {
    this.onCancel.emit();
  }

  onInputChange(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const regex = /^[0-9.]*$/; // Expresión regular para permitir solo números y puntos

    if (!regex.test(input.value)) {
      input.value = input.value.replace(/[^0-9.]/g, ''); // Eliminar cualquier carácter no válido
      this.paymentAmount = parseFloat(input.value); // Actualizar el valor en el modelo
    }
    this.entityForm.controls["Amount"].setValue(input.value);
  }

  paymentMethodSelect(method: PaymentMethodVM) {
    try {
      switch (method.Code) {
        // cash
        case LocalData.PAYMENT_METHOD_CASH:
          this.hideBankReferenceAndMultiplePayment();
          //this.focusOnAmountInput();
          break;
        // voucher
        case LocalData.PAYMENT_METHOD_VOUCHER:
          this.hideBankReferenceAndMultiplePayment();
          //this.focusOnAmountInput();
          break;
        // card
        case LocalData.PAYMENT_METHOD_CARD:
          this.hideReferenceInput = false;
          this.focusOnReferenceInput();
          break;
        // ebank
        case LocalData.PAYMENT_METHOD_EBANK:
          this.hideReferenceInput = false;
          this.focusOnReferenceInput();
          break;
        // multiple
        case LocalData.PAYMENT_METHOD_MULTIPLE:
          this.hideMultiplePay = false;
          this.multiplePay.focusOnCash();
          break;
        // default
        default: this.hideBankReferenceAndMultiplePayment();
          //this.focusOnAmountInput();
          break;
      }
      if (method.IsCurrency) {
        this.paymentMethodIsCurrency(method.Code);
      }
    } catch (e) {
      alert(e);
    }
  }

  hideBankReferenceAndMultiplePayment() {
    this.hideReferenceInput = true;
    this.hideMultiplePay = true;
  }

  focusOnReferenceInput() {
    setTimeout(() => {
      this.reference.nativeElement.focus();
    }, 0);
  }

  focusOnAmountInput() {
    setTimeout(() => {
      this.amountInput.nativeElement.focus();
      this.amountInput.nativeElement.select();
    }, 0);
  }

  onMultiplePaymentChange(value: number) {

  }

  paymentMethodIsCurrency(code: string) {
    let currency: CurrencyVM = this.currencyService.getLocalCurrency(code);
    this.currencyValue = currency.Exchange;
    this.currencyCode = currency.Code;
    this.isCurrencyPaymentMethod = true;

  }

  openTicket(filename: string): void {
    const url = `${environment.ticketsUrl}/${filename}`;
    window.open(url, '_blank');
  }


}
