
<div>
  <!-- Buttons and grid -->
  <div class="__container">
    <!-- header -->
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <b>Venta</b>
      </div>
      <div class="col-md-8 col-sm-12 d-md-flex d-sm-block justify-content-between align-items-center">
        <div class="ticket" *ngIf="ticketVM">
          Ticket: {{ ticketId || ''}}
        </div>
        <div class="total">
          TOTAL: {{ total | currency }}
        </div>
      </div>
    </div>

    <!-- sale buttons md -->
    <div class="btn-group d-none d-md-block">
      <button class="btn btn-outline-info btn-sm"
              (click)="commonProductEvent()">
        [Ctrl + 4]
        &nbsp;
        Producto común
      </button>
      <!--<button class="btn btn-outline-info btn-sm"
          (click)="multipleInsertionEvent()">
    [Ctrl + 2]
    &nbsp;
    Insersión múltiple
  </button>-->
      <button class="btn btn-outline-info btn-sm"
              (click)="wholesaleEvent()">
        [F11]
        &nbsp;
        $ Mayoreo / Menudeo
      </button>
      <button class="btn btn-outline-info btn-sm"
              [disabled]="cashInButtonDisabled"
              (click)="cashInEvent()">
        [F7]
        &nbsp;
        Entrada de efectivo
      </button>
      <button class="btn btn-outline-info btn-sm"
              [disabled]="cashOutButtonDisabled"
              (click)="cashOutEvent()">
        [F8]
        &nbsp;
        Salida de efectivo
      </button>
      <button class="btn btn-outline-info btn-sm"
              (click)="priceConsultationEvent()">
        [F10]
        &nbsp;
        Consulta de precio
      </button>
      <button class="btn btn-outline-info btn-sm pull-right"
              (click)="newTicket()">
        Nuevo Ticket
      </button>
    </div>

    <div class="row d-sm-none toggle-buttons-sm">
      <div class="col-6">
        <button class="btn btn-secondary btn-block"
                (click)="csabClick()">
          Acciones
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-secondary btn-block"
                (click)="cstbClick()">
          Tickets guardados
        </button>
      </div>
    </div>

    <!-- sale buttons sm -->
    <div class="collapse-container"
         [ngClass]="{'collapse': collapseSaleActionButtons }">
      <div class="btn-group-vertical">
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseSaleActionButtons = true; commonProductEvent()">
          Producto común
        </button>
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseSaleActionButtons = true; multipleInsertionEvent()">
          Insersión múltiple
        </button>
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseSaleActionButtons = true; wholesaleEvent()">
          Precio de mayoreo
        </button>
        <button class="btn btn-outline-info btn-dark"
                [disabled]="cashInButtonDisabled"
                (click)="collapseSaleActionButtons = true; cashInEvent()">
          Entrada de efectivo
        </button>
        <button class="btn btn-outline-info btn-dark"
                [disabled]="cashOutButtonDisabled"
                (click)="collapseSaleActionButtons = true; cashOutEvent()">
          Salida de efectivo
        </button>
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseSaleActionButtons = true; priceConsultationEvent()">
          Consulta de precio
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-sm-12">
        <app-search-product #productSearchCmp
                            (onSimpleProductSelect)="openAddProductDialog($event)"
                            (onScaleProductSelect)="addScaleProduct($event)">
        </app-search-product>
      </div>
      <div class="col-md-8 col-sm-12">
        <app-saved-tickets #savedTicketsCmp
                           [collapse]="collapseSavedTickets"
                           (onTicketSelected)="changeTicketEmits($event)">
        </app-saved-tickets>
      </div>
    </div>

    <!--Products Grid-->
    <div [hidden]="hasProducts">
      <div class="jumbotron text-center">
        <h2>Lista vacía</h2>
        <br />
        <strong>Agregue productos a la lista escaneándo el código de barras o escriba para buscar uno</strong>
      </div>
    </div>
    <div [hidden]="!hasProducts">
      <app-generic-grid #grid
                        (rowToDelete)="deleteProductFromGrid($event)"
                        (onCellClicked)="select($event)">
      </app-generic-grid>
    </div>
  </div>

  <!-- footer -->
  <div class="__stickyFooter">
    <!-- footer buttons md -->
    <div class="btn-group d-none d-md-block">
      <button class="btn btn-outline-info btn-sm"
              (click)="openChargeDialog()">
        [F12]
        <br />
        Cobrar
      </button>
      <button class="btn btn-outline-info btn-sm"
              (click)="saveTicketEvent()">
        [F6]
        <br />
        Guardar ticket
      </button>
      <button class="btn btn-outline-info btn-sm"
              (click)="deleteTicketEvent()">
        [Ctrl + 8]
        <br />
        Eliminar ticket actual
      </button>
      <button class="btn btn-outline-info btn-sm"
              (click)="ChangeTicketEvent()">
        [Ctrl + 1]
        <br />
        Cambiar de ticket
      </button>
      <button class="btn btn-outline-info btn-sm"
              (click)="openLastTicketEvent()">
        [Ctrl + 0]
        <br />
        Imprimir último ticket
      </button>
      <button class="btn btn-outline-info btn-sm"
              (click)="SOTDAREvent()">
        [Ctrl + 5]
        <br />
        Ventas del día y devoluciones
      </button>
    </div>

    <div class="row d-sm-none toggle-buttons-sm">
      <div class="col-6">
        <button class="btn btn-primary btn-block"
                (click)="openChargeDialog()">
          Cobrar
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-secondary btn-block"
                (click)="cmobClick()">
          Más Acciones
        </button>
      </div>
    </div>

    <!-- footer buttons sm -->
    <div class="collapse-container footer-collapse-container"
         [ngClass]="{'collapse': collapseMoreOptions }">
      <div class="btn-group-vertical">
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseMoreOptions=true; saveTicketEvent()">
          Guardar ticket
        </button>
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseMoreOptions=true; deleteTicketEvent()">
          Eliminar ticket actual
        </button>
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseMoreOptions=true; ChangeTicketEvent()">
          Cambiar de ticket
        </button>
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseMoreOptions=true; openLastTicketEvent()">
          Imprimir último ticket
        </button>
        <button class="btn btn-outline-info btn-dark"
                (click)="SOTDAREvent()">
          Ventas del día y devoluciones
        </button>
        <button class="btn btn-outline-info btn-dark"
                (click)="collapseMoreOptions=true; openTicketsEvent()">
          Tickets
        </button>
      </div>
    </div>

  </div>

  <!-- details dialog -->
  <kendo-dialog title="Detalles del Producto"
                *ngIf="detailsDialogOpen"
                (close)="closeDetailsDialog()"
                [minWidth]="250"
                [width]="450">

    <form [formGroup]="detailsForm"
          class="__entityForm">

      <a class="btn btn-danger btn-block"
         style="cursor: pointer;"
         data-role="button" button
         (click)="deleteProduct()">
        Eliminar
      </a>

      <br />

      <p class="form-control">
        {{ editableProduct.Name || '' }}
      </p>

      <kendo-floatinglabel text="Cantidad">
        <input kendoTextBox name="Quantity"
               #quantity
               class="form-control"
               type="number"
               aria-label="Cantidad"
               placeholder="Cantidad"
               formControlName="Quantity" />
      </kendo-floatinglabel>

      <p class="form-control">
        {{ editableProduct.UnitName || '' }}
      </p>

      <kendo-floatinglabel text="Precio">
        <input kendoTextBox name="RetailPrice"
               class="form-control"
               type="number"
               aria-label="Precio"
               placeholder="Precio"
               formControlName="RetailPrice" />
      </kendo-floatinglabel>

      <hr />

      <p class="form-control text-right">
        {{ editableProduct.Total | currency }}
      </p>

      <kendo-dialog-actions>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-primary"
                  type="submit"
                  [disabled]="!isDetailsValidForm()"
                  (click)="editProduct()">
            Guardar
          </button>
        </div>
      </kendo-dialog-actions>
    </form>

  </kendo-dialog>

  <!-- product details dialog -->

  <!-- charge dialog -->
  <kendo-dialog *ngIf="chargeDialogOpen"
                (close)="onCloseChargeDialog()"
                [minWidth]="450"
                [width]="800"
                [height]="600">

    <div kendo-dialog-titlebar
         class="row">
      <div class="col-4">
        <h3>Cobrar</h3>
      </div>
      <div class="col-4 text-center">
        <h3>{{total | currency }}</h3>
      </div>
      <div class="col-4 text-right">
        <button kendo-button
                (click)="onCloseChargeDialog()">
          <span class="sr-only">Cerrar</span>
          <i class="fa fa-close"></i>
        </button>
      </div>
    </div>

    <!-- chargeForm -->
    <form [formGroup]="chargeForm"
          class="__entityForm">

      <app-payment-method-button-selector #paymentMethodSelector
                                          (onItemSelect)="paymentMethodSelect($event)">
      </app-payment-method-button-selector>

      <app-customer-selector #customerSelector
                             creditOnly="true"
                             [hidden]="hideCustomerSelector">
      </app-customer-selector>

      <!-- paid & change amount -->
      <div class="row">
        <div class="col-6">
          <kendo-floatinglabel text="Pagado"
                               [hidden]="disablePaid">
            <input kendoTextBox name="Paid"
                   #paid
                   class="form-control"
                   [readonly]="readOnlyAmount"
                   type="number"
                   aria-label="Pagado"
                   placeholder="0.00"
                   (keydown.enter)="focusOnChargeButton()"
                   formControlName="Paid" />
          </kendo-floatinglabel>
        </div>
        <div class="col-6">
          <div [hidden]="disablePaid || isCurrencyPaymentMethod">
            <p class="form-control d-flex justify-content-between align-items-center">
              <b>Cambio:</b>
              <span>{{ change | currency }}</span>
            </p>
          </div>
        </div>
      </div>

      <app-multiple-payment-method #multiplePay
                                   (NewFormEmiter)="onMultiplePaymentChange($event)"
                                   [NewForm]="true"
                                   [AllowCredits]="true"
                                   [hidden]="hideMultiplePay">
      </app-multiple-payment-method>

      <kendo-floatinglabel text="Referencia Bancaria"
                           [hidden]="hideReferenceInput">
        <input kendoTextBox name="BankReference"
               #reference
               class="form-control"
               type="text"
               aria-label="Referencia Bancaria"
               placeholder="Referencia Bancaria"
               (keydown.enter)="focusOnChargeButton()"
               formControlName="BankReference" />
      </kendo-floatinglabel>

      <ng-container *ngIf="isCurrencyPaymentMethod">
        <p class="form-control d-flex justify-content-between align-items-center">
          <b>Tipo de Cambio:</b>
          <span>{{ currencyValue | currency }}</span>
        </p>
        <p class="form-control d-flex justify-content-between align-items-center">
          <b>Total {{ currencyCode }}:</b>
          <span>{{ currencyTotal | number: '1.2-2' }}</span>
        </p>
        <p class="form-control d-flex justify-content-between align-items-center">
          <b>Cambio {{ currencyCode }}:</b>
          <span>{{ currencyChange | number: '1.2-2' }}</span>
        </p>
      </ng-container>

      <hr />

      <kendo-dialog-actions>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-primary"
                  #chargeButton
                  type="submit"
                  [disabled]="!isChargeValidForm() || chargeInProcess"
                  appDisabledWhileLoading
                  (click)="createCharge()">
            Cobrar
          </button>
        </div>
      </kendo-dialog-actions>
    </form>

  </kendo-dialog>
  <!-- charge dialog -->

  <!-- Add product dialog -->
  <kendo-dialog title="Agregar Producto"
                *ngIf="newProductDialogOpen"
                (close)="closeNewProductDialog ()"
                [minWidth]="250"
                [width]="450">

    <form [formGroup]="newProductForm"
          class="__entityForm">

      <p class="form-control">
        [{{ newProduct.Code || '' }}] {{ newProduct.Name || '' }}
      </p>

      <p class="form-control d-flex justify-content-between align-items-center"
         *ngIf="newProduct.UsesInventory">
        Existencia: {{ newProduct.Existence | number }}
      </p>

      <kendo-floatinglabel text="Cantidad">
        <input kendoTextBox name="Quantity"
               #newProductQuantity
               class="form-control"
               type="number"
               aria-label="Cantidad"
               placeholder="Cantidad"
               formControlName="Quantity" />
      </kendo-floatinglabel>

      <p class="form-control">
        {{ newProduct.UnitName || '' }}
      </p>

      <kendo-floatinglabel text="Precio">
        <input kendoTextBox name="RetailPrice"
               class="form-control"
               type="number"
               aria-label="Precio"
               placeholder="Precio"
               formControlName="RetailPrice" />
      </kendo-floatinglabel>

      <hr />

      <p class="form-control text-right">
        {{ newProduct.Total | currency }}
      </p>

      <kendo-dialog-actions>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-primary"
                  type="submit"
                  [disabled]="!isNewProductValidForm()"
                  (click)="addNewProduct()">
            Agregar
          </button>
          <button class="btn btn-outline-secondary"
                  (click)="closeNewProductDialog()">
            Cancelar
          </button>
        </div>
      </kendo-dialog-actions>
    </form>

  </kendo-dialog>

  <!-- sale shortcuts -->
  <sale-shortcuts (charge)="SC_Charge()"
                  (searchField)="focusOnCodeInput()"
                  (commonProduct)="SC_CommonProduct()"
                  (multipleInsertion)="SC_MultipleInsertion()"
                  (wholesalePrice)="SC_WholesalePrice()"
                  (cashIn)="SC_CashIn()"
                  (cashOut)="SC_CashOut()"
                  (priceConsultation)="SC_PriceConsultation()"
                  (saveTicket)="SC_SaveTicket()"
                  (deleteCurrentTicket)="SC_DeleteCurrentTicket()"
                  (changeTicket)="SC_ChangeTicket()"
                  (reprintLastTicket)="SC_ReprintLastTicket()"
                  (salesOfTheDayAndReturns)="SC_SalesOfTheDayAndReturns()"
                  (removeLastProduct)="SC_RemoveLastProduct()"
                  (openTicketsListDialog)="SC_OpenTicketsListDialog()">
  </sale-shortcuts>


  <app-authorization-window #authorizationWindow
                            (onMovementCreated)="authorizedMovementCreated($event)"
                            (onSavedTicketDeleted)="authorizedSavedTicketDeleted($event)">>
  </app-authorization-window>

  <!-- Modals -->
  <!-- common product -->
  <modal-common-product #commonProductModal
                        (commonProductEmiter)="commonProductEmits($event)"
                        (modalClose)="commonProductModalClose()">
  </modal-common-product>

  <!-- multiple insertion -->
  <modal-multiple-insertion #multipleInsertionModal
                            (searchCode)="multipleInsertionSearchCode($event)"
                            (multipleInsertionEmiter)="multipleInsertionEmits($event)"
                            (modalClose)="multipleInsertionModalClose()">
  </modal-multiple-insertion>

  <!-- price consultation -->
  <modal-price-consultation #priceConsultationModal
                            (searchCode)="priceConsultationSearchCode($event)"
                            (modalClose)="multipleInsertionModalClose()">
  </modal-price-consultation>

  <!-- cash in -->
  <modal-cash-in #cashInModal
                 (cashInEmiter)="cashInEmits($event)"
                 (modalClose)="cashInModalClose()">
  </modal-cash-in>

  <!-- cash out -->
  <modal-cash-out #cashOutModal
                  (cashOutEmiter)="cashOutEmits($event)"
                  (modalClose)="cashOutModalClose()">
  </modal-cash-out>

  <!-- change ticket -->
  <modal-change-ticket #changeTicketModal
                       (changeTicketEmiter)="changeTicketEmits($event)"
                       (modalClose)="changeTicketModalClose()">
  </modal-change-ticket>

  <!-- sales of the day  -->
  <window-sales-of-the-day-and-returns #SotDWindow
                                       (windowClose)="SotDWindowClose()">
  </window-sales-of-the-day-and-returns>

  <!-- tickets list -->
  <!--<modal-tickets-dialog #ticketsModal
                        (modalClose)="ticketsModalClose()">
  </modal-tickets-dialog>-->

  <app-exit #exitConfirm
            (exitEmiter)="exitConfirmation()"
            (closeDialog)="exitCancel()">
  </app-exit>

  <modal-sale-error #saleErrorModal></modal-sale-error>
</div>

<!--<div [hidden]="!hideContent">
  <div class="jumbotron text-center">
    <h3>
      El turno activo no corresponde con la fecha actual
    </h3>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Turno activo</b>
          <span class="badge">
            {{ activeShift.CreateDate | date: 'dd/MM/yyyy'}}
          </span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Fecha actual</b>
          <span class="badge">
            {{ todayDate | date: 'dd/MM/yyyy'}}
          </span>
        </div>
        <button class="list-group-item"
                routerLink="/home/cash-closing">
          Hacer corte
        </button>
      </div>
    </div>
  </div>
</div>-->


<app-generic-dialog #genericDialog></app-generic-dialog>
