<!-- Customers balance grid -->
<div [hidden]="showDetails || showForm || showEdition || showPartialPayments || showMovementDetails">

  <div class="row">
    <div class="col-4">
      <h3>
        Saldos
      </h3>
    </div>
    <div class="col-8 text-center">
    </div>
  </div>

  <hr />
  <br />

  <app-generic-grid #grid
                    (onCellClicked)="select($event)">
  </app-generic-grid>



</div>

<!-- Customer movements grid -->
<div [hidden]="!showDetails || showForm || showEdition || showPartialPayments || showMovementDetails">

  <app-delete-confirm-dialog #confirmDialog
                             (onConfirm)="confirmDelete($event)">
  </app-delete-confirm-dialog>

  <div class="row">
    <div class="col-6">
      <h3>
        Movimientos de Cliente
      </h3>
      <h4>
        ({{ customerName || '' }})
      </h4>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-outline-secondary"
              (click)="showDetails=false"
              aria-label="Cerrar">
        Cerrar
        &nbsp;
        <i class="fa fa-close"></i>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="btn-group">
        <button class="btn btn-outline-primary"
                [disabled]="disableActionButtons()"
                (click)="createPay()">
          Abonar
        </button>
        <button class="btn btn-outline-secondary"
                (click)="createStatement()">
          Crear Estado de Cuenta
        </button>
        <button class="btn btn-outline-primary"
                [disabled]="disableActionButtons()"
                (click)="showPartialPayments=true">
          Pagar múltiples Tickets
        </button>
      </div>
    </div>
    <div class="col-6">
      <h4 class="text-center"
          *ngIf="selectedbalance">
        Saldo: {{ selectedbalance.Balance | currency }}
      </h4>
    </div>
  </div>

  <hr />
  <br />

  <app-generic-grid #detailsGrid
                    (rowToPrint)="reprint($event)"
                    (rowToSelect)="viewDetails($event)">
  </app-generic-grid>

</div>

<!-- Customer pay form -->
<div [hidden]="!showForm">
  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">
      <h4>
        Crear Abono
      </h4>
      <br />
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Cliente</b>
          <span class="badge">{{ customerName }}</span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Saldo</b>
          <span class="badge">{{ totalCustomerBalance | currency }}</span>
        </div>
      </div>
      <hr />
      <form [formGroup]="entityForm"
            class="__entityForm">
        <app-unpaid-sales-from-customer-selector #unpaidSalesSelector
                                                 (ticketSelected)="onTicketSelected($event)">
        </app-unpaid-sales-from-customer-selector>

        <div class="d-flex justify-content-end __formButtonsContainer"
             *ngIf="!hideCancelButton">
          <button class="btn btn-secondary"
                  type="button"
                  (click)="reset()">
            Cancelar
          </button>
        </div>
        <div [hidden]="!isTicketSelected">
          <app-payment-method-button-selector #paymentMethodSelector
                                              (onItemSelect)="paymentMethodSelect($event)"
                                              hideCredit="true">
          </app-payment-method-button-selector>

          <app-multiple-payment-method #multiplePay
                                       (NewFormEmiter)="onMultiplePaymentChange($event)"
                                       [NewForm]="true"
                                       [AllowCredits]="false"
                                       [hidden]="hideMultiplePay">
          </app-multiple-payment-method>

          <kendo-floatinglabel text="Monto">
            <input kendoTextBox name="Amount"
                   class="form-control"
                   #amount
                   (click)="amount.select()"
                   [ngClass]="{'is-invalid': entityForm.get('Amount').errors && entityForm.get('Amount').touched}"
                   aria-label="Monto"
                   placeholder="Monto"
                   (keyup.enter)="onEnterKeyPressed($event)"
                   formControlName="Amount" />
          </kendo-floatinglabel>

          <!-- bank reference -->
          <kendo-floatinglabel text="Referencia Bancaria"
                               [hidden]="hideReferenceInput">
            <input kendoTextBox name="BankReference"
                   #reference
                   class="form-control"
                   type="text"
                   aria-label="Referencia Bancaria"
                   placeholder="Referencia Bancaria"
                   (keyup.enter)="onEnterKeyPressed($event)"
                   formControlName="BankReference" />
          </kendo-floatinglabel>

          <ng-container *ngIf="isCurrencyPaymentMethod">
            <p class="form-control d-flex justify-content-between align-items-center">
              <b>Total {{ currencyCode }}:</b>
              <span>{{ currencyTotal | number: '1.2-2' }}</span>
            </p>
            <p class="form-control d-flex justify-content-between align-items-center">
              <b>Tipo de Cambio:</b>
              <span>{{ currencyValue | currency }}</span>
            </p>
            <p class="form-control d-flex justify-content-between align-items-center">
              <b>Total abono:</b>
              <span>{{ totalAmount | currency }}</span>
            </p>
          </ng-container>

          <div class="d-flex justify-content-end __formButtonsContainer">
            <button class="btn btn-primary"
                    type="submit"
                    #saveButton
                    [disabled]="!isValidForm()"
                    appDisabledWhileLoading
                    (click)="save()">
              Crear Abono
            </button>
            <button class="btn btn-secondary"
                    type="button"
                    (click)="reset()">
              Cancelar
            </button>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>

<!-- edit movement form -->
<div [hidden]="!showEdition">
  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">
      <h4>Editar Movimiento</h4>
      <hr />
      <app-list-details #details></app-list-details>
      <br />
      <form [formGroup]="editionForm"
            *ngIf="showEdition"
            class="__entityForm">
        <kendo-floatinglabel text="Monto">
          <input kendoTextBox name="Amount"
                 class="form-control"
                 #amountToEdit
                 aria-label="Monto"
                 placeholder="Monto"
                 formControlName="Amount" />
        </kendo-floatinglabel>
        <div class="d-flex justify-content-end __formButtonsContainer">
          <button class="btn btn-primary"
                  type="submit"
                  [disabled]="!isEditionFormValid()"
                  (click)="update()">
            Guardar Cambios
          </button>
          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancelEdition()">
            Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- partial payments component -->
<ng-container *ngIf="showPartialPayments && selectedbalance">
  <app-partial-payments [customerBalance]="selectedbalance"
                        (onClose)="onPartialPaymentsClose()"
                        (onCancel)="onPartialPaymentsCancel()">
  </app-partial-payments>
</ng-container>
<!-- sale movements payments details -->
<ng-container *ngIf="showMovementDetails && customerMovementSaleBalance">
  <app-customer-movement-details [saleBalance]="customerMovementSaleBalance"
                                 (onClose)="showMovementDetails=false; customerMovementSaleBalance=null;">
  </app-customer-movement-details>
</ng-container>
