import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoadingService } from '../../common/components/loading/loading.service';
import { AuthenticationService } from '../../interceptors/authentication.service';
import { LocalData } from '../../models/LocalData';
import { PermissionType } from '../../models/PermissionType';
import { LoginRequestVM, RolPermissionVM } from '../../models/view-models';
import { RolPermissionService } from '../../services/rol-permission.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  appName: string = environment.appName;

  loginForm: FormGroup;

  @ViewChild("username") username: ElementRef;

  disableLoginButton: boolean = false;

  constructor(private authService: AuthenticationService,
    private permissionService: RolPermissionService,
    private router: Router,
    private loading: LoadingService,
    private fb: FormBuilder) {

    if (this.authService.SessionUserValue()) {
      this.router.navigate(["home/pos/sales"]);
    }

  }

  ngOnInit(): void {
    this.initForm();
    setTimeout(() => {
      this.username.nativeElement.focus();
      this.username.nativeElement.select();
    }, 0);
  }

  initForm() {
    this.loginForm = this.fb.group({
      User: ['', [Validators.required, Validators.minLength(3)]],
      Password: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  isValidForm(): boolean {
    return this.loginForm.valid;
  }

  login() {
    if (this.isValidForm()) {
      this.disableLoginButton = true;
      this.loading.show();

      const userInfo: LoginRequestVM = Object.assign(this.loginForm.value);
      this.authService.login(userInfo)
        .pipe(first())
        .subscribe(response => {
          if (response) {
            this.permissionService.getAll()
              .subscribe((res: RolPermissionVM[]) => {
                if (res) {
                  localStorage.setItem(
                    LocalData.ROUTE_PERMISSIONS,
                    JSON.stringify(res.filter(x => x.PermissionType === PermissionType.ROUTE)));
                  localStorage.setItem(
                    LocalData.ACTION_PERMISSIONS,
                    JSON.stringify(res.filter(x => x.PermissionType === PermissionType.ACTION)));
                  this.router.navigate(['/home/pos/sales']);
                }
              });

          }
          this.disableLoginButton = false;
        }, error => {
          console.log(error);
          this.disableLoginButton = false;
        });
    }
  }

}
