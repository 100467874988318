<!-- all sales -->
<div [hidden]="showDetails || showReturns || showModify">

  <!-- Header -->
  <div class="row">
    <div class="col-6">
      <h3>
        Ventas del Día y Devoluciones
      </h3>
    </div>
    <div class="col-6">
      <app-date-selector (selectDate)="onDateChange($event)"></app-date-selector>
    </div>
  </div>

  <!-- search form -->
  <form [formGroup]="searchForm">
    <div class="row">
      <div class="col-8">
        <kendo-floatinglabel text="Buscar Ticket"
                             style="width:100%;">
          <input kendoTextBox name="ticket"
                 #ticket
                 class="form-control"
                 type="text"
                 [readOnly]="disabledInput"
                 aria-label="Buscar Ticket"
                 placeholder="Buscar Ticket"
                 formControlName="ticket" />
        </kendo-floatinglabel>
      </div>
      <div class="col-4">
        <br />
        <button class="btn btn-primary btn-block"
                type="submit"
                [disabled]="!isValidForm()"
                (click)="searchTicket()">
          Buscar
        </button>
      </div>
    </div>
  </form>

  <hr />
  <br />

  <!-- sales grid -->
  <app-generic-grid #salesGrid
                    (onCellClicked)="select($event)">
  </app-generic-grid>
</div>

<!-- sale details -->
<div [hidden]="!showDetails || showReturns || showModify">

  <!-- authorization window -->
  <app-authorization-window #authorizationWindow
                            (onTicketCanceled)="authorizedTicketCanceled($event)"
                            (onProductsReturned)="authorizedProductsReturned($event)"
                            (onTicketModified)="authorizedTicketModified($event)">
  </app-authorization-window>

  <div class="row">
    <div class="col-6">
      <h3>
        Detalles de {{ detailsType || 'Operación' }}
      </h3>
    </div>
    <div class="col-6 text-right">
    </div>
  </div>

  <br />

  <!-- ticket buttons -->
  <div class="d-flex justify-content-between align-items-center">
    <div class="btn-group">
      <button class="btn btn-outline-info btn-sm"
              [disabled]="disableActionButtons() || cancelTicketButtonDisabled || saleContainsUniqueProduct"
              (click)="showCancelTicketConfirmation()">
        Cancelar Ticket
      </button>
      <button class="btn btn-outline-info btn-sm"
              [disabled]="disableActionButtons() || returnProductsButtonDisabled || isMultiplePaymentMethod || isCurrencyPaymentMethod"
              (click)="showReturnProducts()">
        Devolver Productos
      </button>
      <button class="btn btn-outline-info btn-sm"
              [disabled]="disableActionButtons() || modifyTicketButtonDisabled || isMultiplePaymentMethod || isCurrencyPaymentMethod"
              (click)="showModifyTicket()">
        Modificar Ticket
      </button>
      <app-reprint-ticket [ticketId]="selectedTicketId"></app-reprint-ticket>
    </div>
    <div class="text-center">
      <b>Estado: {{ saleStatus }}</b>
    </div>
    <div class="btn-group">
      <button class="btn btn-outline-info btn-sm"
              (click)="closeDetails()">
        Cerrar <i class="fa fa-close"></i>
      </button>
    </div>
  </div>

  <hr />

  <app-header-details #headerDetails></app-header-details>

  <div class="__headerContainer"
       *ngIf="isCredit">
    <div class="row">
      <div class="col-md-4 col-sm-12 __header">
        <div class="__headerTitle">
          Cliente
        </div>
        <div class="__headerValue">
          {{ selectedSale.CustomerName }}
        </div>
      </div>
    </div>
  </div>

  <div class="__headerContainer"
       *ngIf="isCard">
    <div class="row">
      <div class="col-md-4 col-sm-12 __header">
        <div class="__headerTitle">
          Referencia Bancaria
        </div>
        <div class="__headerValue">
          {{ selectedSale.BankReference }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isMulti"
       class="row">
    <div class="col-md-4 col-sm-12">
      <br />
      <p class="text-center">
        Métodos de Pago
      </p>
      <div class="list-group">
        <div class="list-group-item list-group-item-action flex-column align-items-start active"
             *ngFor="let pay of selectedSale.MultiplePayments">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ pay.PaymentMethodName }}</h5>
            <b>{{ pay.Amount | currency }}</b>
          </div>
          <p class="mb-1">{{ pay.MultiPaymentBankReference }}</p>
          <p class="mb-1">{{ pay.CustomerName }}</p>
        </div>
      </div>
      <br />
    </div>
  </div>

  <div class="__headerContainer"
       *ngIf="isCurrency">
    <div class="row">
      <div class="col-md-6 col-sm-6 __header">
        <div class="__headerTitle">
          Moneda
        </div>
        <div class="__headerValue">
          {{ selectedSale.CurrencyCode }}
        </div>
      </div>
      <div class="col-md-6 col-sm-6 __header">
        <div class="__headerTitle">
          Tipo de Cambio
        </div>
        <div class="__headerValue">
          {{ selectedSale.CurrencyExchangeValue | currency }}
        </div>
      </div>
    </div>
  </div>

  <br />
  <hr />

  <app-generic-grid #detailsGrid>
  </app-generic-grid>

</div>

<!-- return products -->
<div [hidden]="!showReturns">

  <app-return-products #returnProducts
                       (ok)="rpGetSelectedProducts($event)"
                       (cancel)="rpCancel()">
  </app-return-products>

</div>

<!-- modify ticket -->
<div [hidden]="!showModify">

  <app-modify-ticket #modifyTicket
                     (onSave)="mtSave($event)"
                     (onCancel)="mtCancel()">
  </app-modify-ticket>

</div>


<!-- charge dialog -->
<kendo-dialog title="Modificar Ticket"
              *ngIf="chargeDialogOpen"
              (close)="closeChargeDialog()"
              [minWidth]="250"
              [width]="450">

  <form [formGroup]="chargeForm"
        class="__entityForm">

    <p class="text-center"
       style="font-size:2em;">
      {{total | currency }}
    </p>

    <app-payment-method-selector #paymentMethodSelector
                                 (onItemSelect)="paymentMethodSelect($event)">
    </app-payment-method-selector>

    <app-customer-selector #customerSelector
                           creditOnly="true"
                           [hidden]="hideCustomerSelector">
    </app-customer-selector>

    <kendo-floatinglabel text="Referencia Bancaria"
                         [hidden]="hideReferenceInput">
      <input kendoTextBox name="BankReference"
             #reference
             class="form-control"
             type="text"
             aria-label="Referencia Bancaria"
             placeholder="Referencia Bancaria"
             formControlName="BankReference" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Pagado">
      <input kendoTextBox name="Paid"
             #paid
             class="form-control"
             aria-label="Pagado"
             placeholder="Pagado"
             formControlName="Paid" />
    </kendo-floatinglabel>

    <p class="form-control d-flex justify-content-between align-items-center">
      <b>Cambio:</b>
      <span>{{ change | currency }}</span>
    </p>

    <hr />

    <kendo-dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary"
                type="submit"
                [disabled]="!isChargeValidForm()"
                appDisabledWhileLoading
                (click)="createCharge()">
          Cobrar
        </button>
      </div>
    </kendo-dialog-actions>
  </form>

</kendo-dialog>

<!-- cash dialog -->
<kendo-dialog title="{{ cashDialogTitle }}"
              *ngIf="showCashDialog"
              (close)="closeCashDialog()"
              [minWidth]="250"
              [width]="450">

  <div class="list-group">
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Usuario</b>
      <span class="badge">
        {{ selectedMovement.UserName }}
      </span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Monto</b>
      <span class="badge">
        {{ selectedMovement.Amount | currency }}
      </span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Fecha</b>
      <span class="badge">
        {{ selectedMovement.CreateDate | date: 'dd/MM/yyyy H:mm:ss' }}
      </span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center"
         style="cursor:pointer;"
         (click)="openCashTicket()">
      <b>Ticket</b>
      <span class="badge">
        {{ selectedMovement.Ticket }}
      </span>
    </div>
    <div class="list-group-item">
      <b>Concepto</b>
      <br />
      <pre>
        {{ selectedMovement.Concept }}
      </pre>
    </div>
  </div>
  <hr />
  <kendo-dialog-actions>
    <div class="d-flex justify-content-between align-items-center">
      <app-reprint-ticket [ticketId]="selectedMovement.Ticket"></app-reprint-ticket>
      <button class="btn btn-secondary"
              type="button"
              (click)="closeCashDialog()">
        Cerrar
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>


