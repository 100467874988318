import { Directive, ElementRef, Renderer2, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../components/loading/loading.service';

@Directive({
  selector: '[appDisabledWhileLoading]'
})
export class DisableWhileLoadingDirective implements OnDestroy {

  private loadingSubscription: Subscription;

  constructor(private el: ElementRef,
    private renderer: Renderer2,
    private loadingService: LoadingService) {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((isLoading: boolean) => {
      if (isLoading) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
      } else {
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
      }
    });
  }

  @HostListener('keydown.enter', ['$event'])
  handleEnter(event: KeyboardEvent): void {
    if (this.loadingService.loading) {
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }
}
