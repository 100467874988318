
<div class="row">
  <div class="col-md-8 col-sm-12 mx-auto">
    <h4>
      Devolver Productos
    </h4>

    <br />
    <hr />

    <app-products-list-selector #productsSelector></app-products-list-selector>

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              type="submit"
              [disabled]="!isValid()"
              appDisabledWhileLoading
              (click)="returnProducts()">
        Devolver Productos
      </button>
      <button class="btn btn-secondary"
              type="button"
              (click)="close()">
        Cancelar
      </button>
    </div>

  </div>

</div>
