
<kendo-dialog title="Consulta de Precio"
              *ngIf="showDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="450">

  <form [formGroup]="dialogForm"
        [hidden]="showProductDetails">
    <div class="row">
      <div class="col-6">
        <kendo-floatinglabel text="Código">
          <input kendoTextBox name="Code"
                 #code
                 class="form-control"
                 type="text"
                 [readOnly]="disabledInput"
                 aria-label="Código"
                 placeholder="Código"
                 formControlName="Code" />
        </kendo-floatinglabel>
      </div>
      <div class="col-6">
        <br />
        <button class="btn btn-primary btn-block"
                type="submit"
                [disabled]="!isValidForm()"
                appDisabledWhileLoading
                (click)="searchProduct()">
          Buscar
        </button>
      </div>
    </div>
  </form>

  <div class="__productPriceContainer"
       *ngIf="showProductDetails">
    <div class="__productName">
      {{ product.Name }}
    </div>
    <div class="__productPrice">
      {{ product.RetailPrice | currency }}
    </div>
  </div>

  <hr />

  <kendo-dialog-actions>
    <div class="d-flex justify-content-between align-items-center">
      <button class="btn btn-primary"
              type="button"
              #closeButton
              (click)="closeDialog()">
        Cerrar
      </button>
    </div>
  </kendo-dialog-actions>

</kendo-dialog>
