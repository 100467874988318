
const baseUrl: string = "https://torixt-pos-api.ithub-webhost.net";
const name: string = "TORIXT POS";

export const environment = {
  production: true,
  appName: `${name}`,
  apiUrl: `${baseUrl}/api`,
  ticketsUrl: `${baseUrl}/Tickets`,
  cashClosingUrl: `${baseUrl}/CashClosing`,
  customerStatementsUrl: `${baseUrl}/CustomerStatements`,
  CSVTemplate: `${baseUrl}/csv-files/ITHUB-POS-PLANTILLA-PRODUCTOS.xlsx`,
};
