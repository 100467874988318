
export enum ActionPermissionCode {
  CASH_IN = "CASH_IN",
  CASH_OUT = "CASH_OUT",
  MODIFY = "MODIFY",
  RETURNS = "RETURNS",
  CANCELATION = "CANCELATION",
  CREDIT_LIMIT = "CREDIT_LIMIT",
  CREATE_SHIFT = "CREATE_SHIFT",
  DELETE_SAVED_TICKET = "DELETE_SAVED_TICKET",
}
