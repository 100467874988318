import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts'
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { UploadModule } from '@progress/kendo-angular-upload';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { BearerAuthInterceptor } from './interceptors/bearer-auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DisableWhileLoadingDirective } from './common/directives/disable-while-loading.directive';
import { LoaderInterceptor } from './interceptors/loader.interceptor';

@NgModule({
  declarations: [
    DisableWhileLoadingDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    KeyboardShortcutsModule.forRoot(),
    ButtonsModule,
    GridModule,
    PDFModule,
    ExcelModule,
    InputsModule,
    SwitchModule,
    FloatingLabelModule,
    DropDownsModule,
    DialogsModule,
    UploadModule,
    TabStripModule,
    DateInputsModule,
    IntlModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    KeyboardShortcutsModule,
    ButtonsModule,
    GridModule,
    PDFModule,
    ExcelModule,
    InputsModule,
    SwitchModule,
    FloatingLabelModule,
    DropDownsModule,
    DialogsModule,
    UploadModule,
    TabStripModule,
    DateInputsModule,
    IntlModule,
    DisableWhileLoadingDirective,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
})
export class SharedModule { }
