
<div class="container">
  <h4>Abonar a  multiples tickets</h4>

  <div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Cliente</b>
          <span class="badge">
            {{ customerBalance.FullName }}
          </span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Saldo</b>
          <span class="badge">
            {{ customerBalance.Balance | currency }}
          </span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Monto a Pagar</b>
          <span class="badge">
            <div class="currency-input">
              <span class="currency-symbol">$</span>
              <input kendoTextBox #amountInput
                     name="amount"
                     type="text"
                     class="form-control currency-form-control"
                     aria-label="Monto a Pagar"
                     [(ngModel)]="paymentAmount"
                     (input)="onInputChange($event)"
                     placeholder="0.00" />
            </div>
          </span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          {{ selectedSales.length || '0' }} tickets seleccionados
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Total selección</b>
          <span class="badge">
            {{ totalSelected | currency }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <app-payment-method-button-selector #paymentMethodSelector
                                          (onItemSelect)="paymentMethodSelect($event)"
                                          hideCredit="true">
      </app-payment-method-button-selector>
      <br />
      <form [formGroup]="entityForm"
            class="__entityForm">
        <app-multiple-payment-method #multiplePay
                                     (NewFormEmiter)="onMultiplePaymentChange($event)"
                                     [NewForm]="true"
                                     [AllowCredits]="false"
                                     [hidden]="hideMultiplePay">
        </app-multiple-payment-method>

        <kendo-floatinglabel text="Monto">
          <input kendoTextBox name="Amount"
                 class="form-control"
                 aria-label="Monto"
                 placeholder="Monto"
                 readonly
                 formControlName="Amount" />
        </kendo-floatinglabel>

        <!-- bank reference -->
        <kendo-floatinglabel text="Referencia Bancaria"
                             [hidden]="hideReferenceInput">
          <input kendoTextBox name="BankReference"
                 #reference
                 class="form-control"
                 type="text"
                 aria-label="Referencia Bancaria"
                 placeholder="Referencia Bancaria"
                 formControlName="BankReference" />
        </kendo-floatinglabel>

        <ng-container *ngIf="isCurrencyPaymentMethod">
          <p class="form-control d-flex justify-content-between align-items-center">
            <b>Total {{ currencyCode }}:</b>
            <span>{{ currencyTotal | number: '1.2-2' }}</span>
          </p>
          <p class="form-control d-flex justify-content-between align-items-center">
            <b>Tipo de Cambio:</b>
            <span>{{ currencyValue | currency }}</span>
          </p>
          <p class="form-control d-flex justify-content-between align-items-center">
            <b>Total abono:</b>
            <span>{{ totalAmount | currency }}</span>
          </p>
        </ng-container>

        <div class="d-flex justify-content-end __formButtonsContainer">
          <button class="btn btn-primary"
                  type="submit"
                  [disabled]="!isValid()"
                  appDisabledWhileLoading
                  (click)="save()">
            Crear Abono
          </button>
          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>

  <app-multiple-unpaid-sales-from-customer-selector [customerId]="customerBalance.CustomerId"
                                                    (onItemToggle)="calculateTotal($event)">
  </app-multiple-unpaid-sales-from-customer-selector>

</div>
